// Contact.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  return (
    <section>
      <h2>Contact</h2>
      <h3>Get in touch</h3>
      <contact>
        <p>Email: <a href='mailto:alex@dorneean.ro'>alex@dorneean.ro</a></p>
        <p>Phone: <a href='tel:+40749336266'>+40 (749) 336 266</a></p>
        <p>Cluj Napoca, Romania</p>
      </contact>
      <div className='social-icons'>
        <a href='https://www.facebook.com/chafleks' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faFacebook} size='2x' />
        </a>
        <a href='https://twitter.com/ChAFleks' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faXTwitter} size='2x' />
        </a>
        <a href='https://www.linkedin.com/in/chafleks/' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faLinkedin} size='2x' />
        </a>
        <a href='https://github.com/adynetro' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faGithub} size='2x' />
        </a>
      </div>
    </section>
  );
}

export default Contact;