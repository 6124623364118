import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('Alexandru <br /> Chiscari');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrolled(currentScrollY > 0);
      setHeaderTitle(currentScrollY > 0 ? 'Alex Ch' : 'Alexandru <br /> Chiscari');
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={isScrolled ? 'scrolled' : ''}>
      <SwitchTransition>
        <CSSTransition
          key={headerTitle}
          timeout={500}
          classNames="fade"
        >
          <h1 dangerouslySetInnerHTML={{ __html: headerTitle }}></h1>
        </CSSTransition>
      </SwitchTransition>
      <h2>online portfolio & space</h2>
      <nav>
        <ul>
          <li><Link to="/">About Me</Link></li>
          <li><Link to="/projects">Projects</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;