// Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import packageJson from '../../package.json';

const { version } = packageJson;
const { name } = packageJson;
const currentYear = new Date().getFullYear(); // Get the current year

function Footer() {
  return (
    <footer>
      <div className='nospace'>
        <p>© {currentYear} {name} - v{version}</p> {/* Display version here */}
      </div>
      <div className='social-icons'>
        <a href='https://www.facebook.com/chafleks' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href='https://twitter.com/ChAFleks' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
        <a href='https://www.linkedin.com/in/chafleks/' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a href='https://github.com/adynetro' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faGithub} />
        </a>
      </div>
    </footer>
  );
}

export default Footer;